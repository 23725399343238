<template>
  <div class="auth-wrapper">
    <div class="auth-content">
      <div class="mb-4 text-center">
        <a href="/">
          <img src="../../assets/images/logo/logo_dark.png" class="img-fluid" />
        </a>
      </div>
      <div class="card">
        <notification-container />
        <div class="card-body" v-if="!isConfirm">
          <BaseAlert
            message="Un mail de confirmation vous a été envoyé, Veillez-vous connectez à
            votre boîte de messagerie pour confirmer."
            type="success"
          />
          <hr />
          <p
            class="mb-0"
            :class="{ 'text-secondary': resend, 'text-muted': !resend }"
          >
            {{
              resend
                ? "Nouveau mail envoyé avec succès"
                : "Besoin d'un nouveau lien de confirmation ?"
            }}
            <a href="#" @click.prevent="resendVerifyLink()" v-if="!resend">
              Cliquez ici pour renvoyer.
            </a>
          </p>
        </div>
        <div class="card-body" v-else>
          <template v-if="error">
            <BaseAlert :message="error" type="danger" />
            <a
              href="#"
              class="text-info"
              @click.prevent="resendVerifyLink()"
              v-if="!resend"
            >
              Cliquez ici pour envoyer un nouveau lien.
            </a>
          </template>
          <template v-if="message">
            <BaseAlert :message="message" type="info" />
            <BaseLoader :is-small="true" />
          </template>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NProgress from "nprogress";
import NotificationContainer from "../../components/notification/NotificationContainer";

export default {
  name: "EmailConfirmation",
  components: { NotificationContainer },
  computed: mapState("auth", ["user"]),
  data() {
    return {
      resend: false,
      isConfirm: false,
      message: null,
      error: null
    };
  },

  created() {
    if (this.user.email_verified_at) this.$router.push({ name: "dashboard" });
    else {
      if (this.$route.params.token) {
        this.isConfirm = true;
        this.$store
          .dispatch("auth/confirmVerifyEmail", {
            token: this.$route.params.token
          })
          .then(({ message }) => {
            this.message = message;
            setTimeout(() => this.$router.push({ name: "dashboard" }), 3000);
          })
          .catch(err => {
            if (err.message) this.error = err.message;
          });
      }
    }
  },

  metaInfo() {
    return {
      title: this.$t(`menu.${this.$route.name}.title`)
    };
  },

  methods: {
    resendVerifyLink() {
      NProgress.start();
      this.isConfirm = false;
      this.$store
        .dispatch("auth/resendVerifyEmail")
        .then(() => {
          this.resend = true;
          NProgress.done();
        })
        .catch(() => {
          NProgress.done();
        });
    }
  }
};
</script>

<style scoped></style>
